import { RouteConfig } from "vue-router/types/router";
import { NavigationGuard, Route } from "vue-router";
import { PageMixin } from "app/lib/ts/PageMixin";
import RouteGenerator from "modules/core/lib/RouteGenerator";
import LanguageBO from "modules/core/business/LanguageBO";
import { Application } from "modules/core/lib/Holder";
import SessionBO from "modules/core/business/SessionBO";
import EnvironmentConfig from "modules/core/lib/EnvironmentConfig";
import ContentLoading from "modules/core/components/content-loading/ContentLoading";
import Loader from "modules/core/components/interface/loader/Loader";
import Util from "../lib/Util";
import Api from "../lib/Api";
import { CoreComponentMixin } from "../mixins/ts";

import BlankPage from "./BlackPage.vue";
import App from "../lib/App";

export default (language: string): RouteConfig[] => {
    const publicLanguage = LanguageBO.convertToPublicLanguage(language);
    const routeGenerator = RouteGenerator.getInstance();
    const app = Application.getInstance();
    const routes: any[] = [];
    // const routes: RouteConfig[] = [];

    routes.push({
        name: "blank",
        path: "/blank",
        component: BlankPage,
    });

    routes.push({
        name: "cmd",
        path: "/cmd/:cmd",

        beforeEnter: async (to: Route, from: Route, next: Function) => {
            console.log(`ROUTE: cmd/${to.params.cmd}`, { to, from });
            if (!to.params.cmd) return next();

            const redirection =
                await app.deepLinkingManager.getRedirectionForCMD({
                    command: to.params.cmd,
                    query: to.query,
                });

            // const cmd = new Deeplinking(
            //     to.params.cmd as AllowedActions,
            //     to.query,
            // );
            // const redirection = await cmd.manageRedirections();

            if (redirection) {
                // @ts-ignore
                return next(redirection);
            }
            // @ts-ignore
            return next(
                app.generalConfig().redirectToHomeIfCmdNotFound
                    ? RouteGenerator.getInstance().home()
                    : RouteGenerator.getInstance().notFound(),
            );
        },
    });

    routes.push({
        name: "noLang",
        path: "/",
        redirect: () => {
            return app.generalConfig().presentDefaultView
                ? { name: "home" }
                : null;
        },
    });

    routes.push({
        name: "validateEmail",
        path: "/validate-email",
        component: {
            async beforeRouteEnter(
                to: Route,
                from: Route,
                next: NavigationGuard,
            ) {
                const validate = await app.validateEmail();
                // @ts-ignore
                next(validate);
            },
        },
    });

    routes.push({
        name: "sessionStart",
        path: "/session-start",
        beforeEnter: async (to: Route, from: Route, next: Function) => {
            // const a = true;
            // if (a) return next({ name: "session", query: to.query });

            console.log("ROUTE: sessionStart", { to, from });
            if (to.query.sessionId) {
                next();
                return;
            }

            if (to.query.conclusion) {
                const response =
                    await SessionBO.getInstance().doNewSessionForReason(
                        `[[conclusion=${to.query.conclusion}]]`,
                    );

                if (response && response.session?.sessionId) {
                    // @ts-ignore
                    next(routeGenerator.session(response.session.sessionId));
                }
            } else if (to.query.category) {
                const response = await SessionBO.getInstance().doNewSession({
                    reason: `[[category=${to.query.category}]]`,
                });

                if (response && response.session?.sessionId) {
                    // @ts-ignore
                    next(routeGenerator.session(response.session.sessionId));
                }
            } else {
                const { reason, externUserId } = to.query as {
                    [key: string]: any;
                };

                const response = await SessionBO.getInstance().doNewSession({
                    reason: reason || null,
                    impersonatedExternUserId: externUserId || undefined,
                });
                if (response && !response.error) {
                    response.sessionId &&
                        // @ts-ignore
                        next(
                            RouteGenerator.getInstance().session(
                                response.sessionId,
                                !!externUserId,
                            ),
                        );
                }
            }
        },
    });
    // component: {
    //     components: { Loader },
    //     template: `<loader class="q-pa-lg"></loader>`,
    //     extends: [CoreComponentMixin, PageMixin],
    //     async created() {
    //         if (
    //             Object.keys((this as any).$route.query).includes(
    //                 "conclusion",
    //             )
    //         ) {
    //             const response =
    //                 await SessionBO.getInstance().doNewSessionForReason(
    //                     `[[conclusion=${(this as any).$route.query.conclusion}]]`,
    //                 );

    //             if (response && response.session?.sessionId) {
    //                 // @ts-ignore
    //                 this.$router.push(
    //                     routeGenerator.session(response.session.sessionId),
    //                 );
    //             }
    //         } else if (
    //             Object.keys((this as any).$route.query).includes("category")
    //         ) {
    //             const response = await SessionBO.getInstance().doNewSession(
    //                 {
    //                     reason: `[[category=${(this as any).$route.query.category}]]`,
    //                 },
    //             );

    //             if (response && response.session?.sessionId) {
    //                 // @ts-ignore
    //                 this.$router.push(
    //                     routeGenerator.session(response.session.sessionId),
    //                 );
    //             }
    //         } else {
    //             const { reason, externUserId } = (this as any).$route
    //                 .query as {
    //                 [key: string]: any;
    //             };

    //             const response = await SessionBO.getInstance().doNewSession(
    //                 {
    //                     reason: reason || null,
    //                     impersonatedExternUserId: externUserId || undefined,
    //                 },
    //             );
    //             if (response && !response.error) {
    //                 response.sessionId &&
    //                     // @ts-ignore
    //                     (await this.$router.replace(
    //                         RouteGenerator.getInstance().session(
    //                             response.sessionId,
    //                             !!externUserId,
    //                         ),
    //                     ));
    //             }
    //         }
    //     },
    // },
    // });

    routes.push({
        name: "professionalNewImpersonatedSession",
        path: `/${publicLanguage}/new-impersonated-session`,
        component: {
            async beforeRouteEnter(
                to: Route,
                from: Route,
                next: NavigationGuard,
            ) {
                next(
                    RouteGenerator.getInstance().newSessionWithReasonForConsultation(
                        null,
                        true,
                    ) as any,
                    null as any,
                    null as any,
                );
            },
        },
    });

    // routes.push({
    //     name: "cmdOld",
    //     path: `/cmd/:cmd`,

    // });

    routes.push({
        name: "cmdOld",
        path: `/c/:cmd`,
    });

    if (app.environment() !== "pro") {
        routes.push({
            name: "appPresets",
            path: `/app-presets`,
            component: () =>
                import("modules/core/components/app-presets/AppPresets"),
        });
    }

    routes.push({
        name: "oddCastDev",
        path: `/oddcast`,
        component: () =>
            import("modules/checker/components/oddcast_avatar/OddcastAvatar.vue"),
    });

    routes.push({
        name: "testCovid19",
        path: `/test-covid-19`,
        component: {
            async beforeRouteEnter(
                to: Route,
                from: Route,
                next: NavigationGuard,
            ) {
                const response =
                    await SessionBO.getInstance().doNewSessionForReason(
                        "[[conclusion=11955041-4045-4759-ae71-7d4acfcb0382]]",
                    );

                if (response.session?.sessionId) {
                    // @ts-ignore
                    next(routeGenerator.session(response.session.sessionId));
                }
            },
        },
    });
    routes.push({
        name: "sessionReportDownloadPdf",
        path: `/session-report-download-pdf`,
        component: {
            extends: ContentLoading,
            async created() {
                // @ts-ignore
                const { sessionId } = this.$route.query;
                const json = {
                    sessionId,
                    language,
                };

                // Force token
                // if (!MutexManager.getInstance().acquired) {
                //    await app.renewAuthenticationToken();
                // }

                const url = Util.fileDownloadUrl(
                    {
                        type: "method",
                        method: app.generalConfig().hospitalMode
                            ? "hospital/SessionInfoPdf"
                            : "sessionInfoPdf",
                        params: JSON.stringify(json),
                        authToken: app.authToken,
                        userId: EnvironmentConfig.getValue("APP_API_USER_ID"),
                    },
                    EnvironmentConfig.getValue("APP_API_ENDPOINT"),
                );

                window.location.replace(url);
            },
        },
    });

    routes.push({
        name: "externUserPrescriptionPdf",
        path: `/prescription-pdf`,
        beforeEnter: (to: Route, from: Route, next: NavigationGuard) => {
            const params = JSON.stringify({
                externUserId: to.query.externUserId,
                prescriptionId: to.query.prescriptionId,
            });
            window.location.href = Api.getInstance().fileDownloadUrl({
                type: "method",
                method: "externUserPrescriptionPdf",
                params: encodeURI(params),
            });
        },
    });

    return routes;
};
