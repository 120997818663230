// eslint-disable-next-line max-classes-per-file
import { dispatchPublicEvent } from "@mediktor-web/common/events/public";
import _merge from "lodash/merge";
import queryString from "query-string";
import moment from "moment";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import compareVersions from "compare-versions";
import isbot from "isbot";
import axiosRetry from "axios-retry";
import Storage from "modules/core/lib/Storage";
import ErrorResponseCodeEnum from "modules/core/enums/ErrorResponseCodeEnum";
import { MutexManager } from "modules/core/lib/Mutex";
import { Application } from "modules/core/lib/Holder";
import { ErrorResponse } from "modules/core/model/errorResponse";
import Ui from "modules/core/lib/Ui";
import App from "modules/core/lib/App";
import { GenericResponse } from "modules/core/model/genericResponse";
import { GenericRequest } from "modules/core/model/genericRequest";
import { AppEvents } from "modules/core/lib/events";
import { OptionalObject } from "modules/core/types/misc";
import config from "../config/app";
import Util, { objectToQueryString } from "./Util";
import EnvironmentConfig from "./EnvironmentConfig";

// Exponential back-off retry delay between requests
axiosRetry(axios, { retryDelay: axiosRetry.exponentialDelay });

type UploadFileTypes = "ATTACHMENT";

export type AdditionalServiceParams = {
    __alertOnError?: boolean;
    __querystring?: Record<string, any>;
};

const isBot = isbot(navigator.userAgent);

export class ApiServerError extends Error {
    code: string;

    constructor(error: ErrorResponse) {
        super(error.description);

        this.message = error.description || "";
        this.code = error.code || "";
    }
}

export default class Api {
    static readonly API_VERSION = "4.1.4";

    static instance?: Api;

    get endpoint(): string {
        return EnvironmentConfig.getValue("APP_API_ENDPOINT");
    }

    get authCode(): string {
        return EnvironmentConfig.getValue("APP_API_AUTH_CODE");
    }

    get userId(): string {
        return EnvironmentConfig.getValue("APP_API_USER_ID");
    }

    // language:string = config.defaultLanguage;

    handleUiCommunication: boolean = true;

    onServerError?: (errorCode: ErrorResponse, errorMessage?: string) => void;

    requestQueue?: { (): void }[];

    serviceBasePath: string = "";

    protected app!: App;

    setApp(app: App) {
        this.app = app;
    }

    static getInstance(): Api {
        if (!Api.instance) {
            Api.instance = new this();
        }

        return Api.instance;
    }

    releaseQueue() {
        this.requestQueue?.forEach((request) => request());
        this.requestQueue = [];
    }

    /* setCredentials(authCode:string, userId:string) {
        this.authCode = authCode;
        this.userId = userId;
    }

    setUserCredentials(externUserId:string, authToken:string, deviceId?:string) {
        this.authToken = authToken;
        this.externUserId = externUserId;

        if (!Util.isNullOrEmpty(deviceId)) {
            this.deviceId = deviceId;
        }
    } */

    mandatoryParams(): GenericRequest {
        const params: GenericRequest = {
            apiVersion: Api.API_VERSION,
            appVersion:
                config.appVersion === "@next"
                    ? undefined
                    : config.appVersion || undefined, // server returns error if the version is not a number
            appId: config.appId || undefined,
            deviceType: this.app.generalConfig().deviceType,
            deviceToken: this.app.generalConfig().deviceToken || undefined,
            language: this.app.currentLanguageCode()?.replace("-", "_"),
            timezoneRaw: this.timezone(),
            deviceId: this.app.deviceId || undefined,
        };

        if (
            !this.app.geolocationEnabled() &&
            Util.urlQueryParam("geolocationLatitude") &&
            Util.urlQueryParam("geolocationLongitude")
        ) {
            params.latitude = parseFloat(
                Util.urlQueryParam("geolocationLatitude")!,
            );
            params.longitude = parseFloat(
                Util.urlQueryParam("geolocationLongitude")!,
            );
        }

        return params;
    }

    timezone(): number {
        const now = moment();

        const timezone = now.utcOffset();
        // if(now.isDST()) timezone -= 1;

        return timezone;
    }

    getResolvedEndpoint(path = ""): string {
        return `${this.endpoint}/services/${this.serviceBasePath}${path}`;
    }

    setupStorageForService(name: string, time: number | boolean) {
        const cacheTime =
            typeof time === "boolean" && time === false ? 1 : (time as number);
        const storage = new Storage(name, cacheTime);

        return storage;
    }

    cacheName(name: string, suffix: Record<string, any>): string {
        delete suffix.useCache; // FIXME
        delete suffix.language; // FIXME

        let cacheKeyName = `request__${name}_`;
        cacheKeyName += JSON.stringify(suffix);

        if (cacheKeyName[cacheKeyName.length - 1] === "_") {
            cacheKeyName = cacheKeyName.substring(0, cacheKeyName.length - 1);
        }

        return cacheKeyName;
    }

    static isAuthBearer(): boolean {
        return compareVersions.compare(Api.API_VERSION, "4.1.0", ">=");
    }

    /*
     * @deprecated Please use service() method
     */
    async callServiceWithPromise<T, K = OptionalObject>(
        serviceName: string,
        params: AdditionalServiceParams & K,
        shouldBeJson: boolean = true,
        forceResponseType?: string,
        dispatch: boolean = true,
        useBasicAuth: boolean = false,
    ): Promise<T> {
        return this.service<T, K>(
            serviceName,
            params,
            shouldBeJson,
            forceResponseType,
            dispatch,
            useBasicAuth,
        );
    }

    async service<T, K = OptionalObject>(
        serviceName: string,
        params?: AdditionalServiceParams & K,
        shouldBeJson: boolean = true,
        forceResponseType: string | null = null,
        dispatch: boolean = true,
        useBasicAuth: boolean = false,
    ): Promise<T> {
        const fn = async (): Promise<T> => {
            const { encapsulatedDeviceType } = this.app.generalConfig();
            const data = _merge(
                {
                    useCache: 0,
                    ...(Boolean(encapsulatedDeviceType) && {
                        encapsulatedDeviceType,
                    }),
                },
                this.mandatoryParams(),
                params || {},
            );

            // let queryParams = this.authBasicParams(typeof params.query === "object" && params.query ? params.query : {});
            const config: AxiosRequestConfig = { headers: {} };

            if (!useBasicAuth /*! params["_useBasicAuth"] */) {
                // TODO: really bad way of doing things
                // queryParams = "";
                // debugger;
                /* if (this.app.authToken) */ config.headers!.Authorization = `Bearer ${this.app.authToken}`;
            } else {
                config.headers!.Authorization = `Basic ${this.authCode}`;

                // delete params["_useBasicAuth"];
            }

            if (forceResponseType) {
                config.headers!.responseType = forceResponseType;
            }

            let [service, queryString] = serviceName.split("?");
            if (queryString === undefined) queryString = "";
            if (params?.__querystring)
                queryString += `&${objectToQueryString(
                    params.__querystring,
                    false,
                )}`;
            const cacheKeyName = this.cacheName(service, params || {});
            const url =
                this.getResolvedEndpoint(service) +
                (queryString ? `?${queryString}` : "");
            const storage = this.setupStorageForService(
                cacheKeyName,
                data.useCache,
            );

            if (!isBot && data.useCache && storage.exists()) {
                const cacheData = storage.getByName("data");

                if (!Util.isNullOrEmpty(cacheData)) {
                    console.info(
                        "Using cache: ",
                        service /* , cacheKeyName, cacheData */,
                    );

                    return cacheData;
                }
            }

            let response: AxiosResponse<GenericResponse>;

            // try {
            response = await axios.post<GenericResponse>(url, data, config);
            // }catch(error) {
            //    if(!getAxiosResponseFromError(error)) {
            //        alert("Network error");
            //     }
            // }

            if (response.data?.error) {
                // new AppEvents.RequestError().emit({
                //     service: serviceName,
                //     error: response.data.error!,
                // });
                dispatchPublicEvent({
                    name: "network:request_error",
                    payload: {
                        service: serviceName,
                        code: response.data.error.code!,
                        description: response.data.error.description!,
                    },
                });

                // TODO: test me
                if (
                    response.data.error.code ===
                    ErrorResponseCodeEnum.ERROR_CODE_UNKNOWN
                )
                    throw new ApiServerError(response.data.error);

                if (
                    response.data.error.code ===
                    ErrorResponseCodeEnum.ERROR_CODE_AUTHTOKEN_INVALID
                ) {
                    // this.onServerError(response.data.error);
                    // If the error is not user corrupted related (ex. me666) then we need to handle it silently,
                    // otherwise we need to throw an error so we can catch it and act accordingly
                    // this.authToken = Application.getInstance().authToken;

                    if (!MutexManager.getInstance().acquired) {
                        await Application.getInstance().renewAuthenticationToken();

                        return this.service(
                            service,
                            params,
                            shouldBeJson,
                            forceResponseType,
                            false,
                        );
                    }
                    return this.service(
                        service,
                        params,
                        shouldBeJson,
                        forceResponseType,
                    );
                }
                if (
                    response.data.error.code ===
                        ErrorResponseCodeEnum.ERROR_CODE_WIPEOUT ||
                    response.data.error.code ===
                        ErrorResponseCodeEnum.ERROR_CODE_WIPEOUT_REGISTERED_USER ||
                    response.data.error.code ===
                        ErrorResponseCodeEnum.ERROR_CODE_WIPEOUT_NO_USER_REGENERATION
                ) {
                    console.log("API fatal error", this, serviceName, params);
                    await Application.getInstance().handleUserFatalError(
                        new ApiServerError(response.data.error),
                    );
                } else if (this.handleUiCommunication) {
                    console.error(serviceName, response.data.error);
                    const message =
                        response.data.error.description ||
                        this.app.localeText(
                            `error.${response.data.error.code}`,
                        );

                    if (params?.__alertOnError !== false) {
                        if (
                            message &&
                            response.data.error.code !==
                                ErrorResponseCodeEnum.ERROR_CODE_NO_PERMISSIONS &&
                            response.data.error.code !==
                                ErrorResponseCodeEnum.ERROR_CODE_NO_PERMISSIONS_REGISTERED_USER &&
                            response.data.error.code !==
                                ErrorResponseCodeEnum.ERROR_CODE_NO_PERMISSIONS_ACCESS_ANONYMOUS_USER_INFO &&
                            response.data.error?.description !==
                                "Too many incorrect requests."
                        ) {
                            // Ui.showAlert(`${message}${app.showDevelopmentTip ? `<br /><small style="font-style:italic; color: #ccc;">${response.data.error.code} (${serviceName})</small>` : null}`);
                            Ui.showAlert(message);
                        }
                    } else {
                        throw new ApiServerError(response.data.error);
                    }
                }

                // throw new ApiServerError(response.data.error as ErrorResponse);
            } else if (
                !isBot &&
                data.useCache &&
                response.status >= 200 &&
                response.status < 300
            ) {
                try {
                    storage.save("data", response.data);
                } catch (e) {}
                // storage.save("lastEdited", response.data.lastEdited);
            }

            return response.data as T;
        };

        return dispatch ? MutexManager.getInstance().dispatch(fn) : fn();
    }

    /* authBasicParams(data = {}) {
        let params = {};

        if(this.authToken != null) params.authToken = this.authToken;

        params = _merge(params, data);

        return queryString.stringify(params);
    } */

    fileDownloadUrl(params: Record<string, any> = {}): string {
        const query = queryString.stringify(
            _merge(
                {
                    type: "ATTACHMENT",
                    authToken: this.app.authToken,
                },
                params,
            ),
            { encode: false },
        );

        let { endpoint } = this;
        endpoint = endpoint.replace("/services", "");

        return `${endpoint}/downloads/File.Download?userId=${this.userId}&${query}`;
    }

    uploadFile(
        file: Blob,
        filename: string,
        defaultId?: string,
        type: string = "ATTACHMENT",
        onProgressCallback = (loaded: number, total: number) => {},
    ): Promise<unknown> {
        const id = defaultId || Util.uniqueId();

        return new Promise((resolve, reject) => {
            this.upload(
                {
                    type,
                    id,
                },
                file,
                filename,
                (status) => {
                    if (status === 200) {
                        resolve(id);
                    } else {
                        reject(status);
                    }
                },
                (loaded, total) => {
                    onProgressCallback(loaded, total);
                },
            );
        });
    }

    async upload(
        params: Record<string, any> = {},
        file: Blob,
        filename: string,
        callback: (status: number) => void = (status) => {},
        progressCallback: (loaded: number, total: number) => void,
    ) {
        const finalParams = _merge(
            {
                type: "ATTACHMENT",
                filename,
                userId: this.userId,
                authToken: this.app.authToken,
            },
            params,
        );
        const query = queryString.stringify(finalParams, { encode: true });
        const endpoint = this.endpoint.replace("/services", "");

        const uri = `${endpoint}/uploads/File.Upload?${query}`;
        const fileContent = file; // typeof file === "string" ? file : await Util.getImageData(file);
        const xhr = new XMLHttpRequest();

        xhr.onload = function (event) {
            // finalization upload file
            callback(xhr.status);
        };
        xhr.onreadystatechange = function (event) {};
        xhr.upload.onprogress = function (event) {
            // byte progress
            progressCallback(event.loaded, event.total);
            // TODO show the progress
        };
        xhr.open("PUT", uri, true);
        xhr.setRequestHeader("Content-Type", "application/octet-stream");
        xhr.setRequestHeader("Authorization", `Basic ${this.authCode}`);
        xhr.send(fileContent);
    }

    setHospitalPath() {
        this.serviceBasePath = "hospital/";
    }
}
