import { dispatchPublicEvent } from "@mediktor-web/common/events/public";
import compareVersions from "compare-versions";
import { Api } from "modules/core";
import { AppEvents } from "modules/core/lib/events";
import App from "modules/core/lib/App";
import { Application } from "./Holder";

type SuccessCallback = { (position: GeolocationPosition): void };
type ErrorCallback = { (): void };

export default class GeolocalizationManager {
    static instance?: GeolocalizationManager;

    private app?: App;

    geoOptions: Record<string, any> = {
        enableHighAccuracy: true,
        timeout: 10 * 1000,
        maximumAge: 5 * 60 * 1000,
    };

    errorAttempts: number = 0;

    static getInstance(): GeolocalizationManager {
        if (!GeolocalizationManager.instance) {
            GeolocalizationManager.instance = new this();
        }

        return GeolocalizationManager.instance;
    }

    setApp(app: App) {
        this.app = app;
    }

    isGeoApiVersion(): boolean {
        return compareVersions.compare(Api.API_VERSION, "4.0.11", ">=");
    }

    async startUserGeolocationDetection(
        successCallback: SuccessCallback = async (position) => {},
        errorCallback: ErrorCallback = async () => {},
    ) {
        if (this.app?.generalConfig().capabilities.location.delegateRequest) {
            // new AppEvents.LocationRequest().emit();
            dispatchPublicEvent({ name: "app:localization_requested" });
            new AppEvents.LocationShare().catch((location) => {
                if (location) {
                    successCallback({ timestamp: 0, coords: location[0] });
                }
            });

            return;
        }

        if ("permissions" in navigator) {
            try {
                const geolocationPermission: PermissionStatus =
                    await navigator.permissions.query({ name: "geolocation" });

                if (geolocationPermission) {
                    if (geolocationPermission.state === "granted") {
                        console.log("permission granted!");
                        await this.navigatorGeolocationAction(
                            successCallback,
                            errorCallback,
                        );
                    } else if (geolocationPermission.state === "prompt") {
                        console.log("permission prompt!");
                        await this.navigatorGeolocationAction(
                            successCallback,
                            errorCallback,
                        );
                    } else if (geolocationPermission.state === "denied") {
                        console.log("permission denied!");
                        await errorCallback();
                    } else console.log("permission not setted");
                } else {
                    await this.navigatorGeolocationAction(
                        successCallback,
                        errorCallback,
                    );
                }
            } catch (error) {
                await this.navigatorGeolocationAction(
                    successCallback,
                    errorCallback,
                );
                // throw new Error(
                //     "Permissions API is not compatible with your browser, fallback to Geolocation API",
                // );
            }
        } else {
            await this.navigatorGeolocationAction(
                successCallback,
                errorCallback,
            );
        }
    }

    async navigatorGeolocationAction(
        succesCallback: SuccessCallback = async (position) => {},
        errorCallback: ErrorCallback = async () => {},
    ) {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    await this.geoSuccess(position, succesCallback);
                },
                async (error) => {
                    await this.geoError(error, errorCallback, succesCallback);
                },
                this.geoOptions,
            );
        }
    }

    async geoSuccess(
        position: GeolocationPosition,
        succesCallback: SuccessCallback,
    ) {
        // if (!Util.isNullOrEmpty(position)) {
        console.info("Geolocation succes:", position);

        /*
        let storage = new Storage("mediktorModuleChecker", 2);
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        storage.save("location", {latitude, longitude});
        */

        await succesCallback(position);
        // }
    }

    async geoError(
        error: GeolocationPositionError,
        errorCallback: ErrorCallback,
        succesCallback: SuccessCallback,
    ) {
        console.info("Geolocation error:", error);
        if (error.code === 1) {
            await errorCallback();
        } else if (error.code === 2) {
            /*       Notify.create({
        message: error.message,
        timeout: 1000,
        icon: 'info',
        position: 'center'
      }); */
        } else if (this.errorAttempts === 3) {
            /*         Notify.create({
          message: error.message,
          timeout: 1000,
          icon: 'info',
          position: 'center'
        }); */
            this.errorAttempts = 0;
        } else {
            await this.startUserGeolocationDetection(
                succesCallback,
                errorCallback,
            );
            this.errorAttempts += 1;
        }
    }

    /*
    startLocationConfig(){
        if(!Application.getInstance().generalConfig().enableLocationSettings) return this.saveIsLocationEnabled(false);
        if(this.getIsLocationEnabled() === null) this.saveIsLocationEnabled(false);
        else this.saveIsLocationEnabled(this.getIsLocationEnabled());
    }
    */

    isLocationEnabled(): boolean {
        /*
        if(!Application.getInstance().generalConfig().enableLocationSettings) return false;
        if(this.getIsLocationEnabled() === null) return false;

        return this.getIsLocationEnabled();
        */

        return Application.getInstance().generalConfig().enableLocationSettings;
    }

    /*
    saveIsLocationEnabled(isLocationEnabled:boolean) {
        AppStorageManager.getInstance().saveIsLocationEnabled(isLocationEnabled);
    }

    getIsLocationEnabled(): boolean {
        return AppStorageManager.getInstance().getIsLocationEnabled();
    }
    */
}
